<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">物流模板</h3>
    </div>
    <div class="btnbox">
      <span class="shou addGoods" @click="addTemplate">
        <i class="el-icon-folder-add"></i> 添加模板
      </span>
      <div class="tiaojian">
        <div class="left">
          <strong>模板名称: </strong
          ><el-input
            v-model="form.name"
            placeholder="请输入模板名称"
          ></el-input>
        </div>
        <span class="shaixuan shou" @click="search">筛选</span>
      </div>
    </div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="name" label="模板名称" width="180">
      </el-table-column>
      <el-table-column label="物流区域" width="100">
        <div class="caozuo">
          <el-button size="mini" type="text" round>查看物流区域</el-button>
        </div>
      </el-table-column>
      <el-table-column type="expand">
        <div class="arealist" slot-scope="scope">
          <div class="chargearea" v-if="scope.row.charge_area_name">
            <h3>收费区域：</h3>
            <template>
              <div
                class="chargeareaDetail"
                v-for="(item, index) in scope.row.charge_area_name"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </template>
          </div>
          <div class="chargearea" v-if="scope.row.disable_area_name">
            <h3>禁用区域：</h3>
            <div
              class="chargeareaDetail"
              v-for="(item, index) in scope.row.disable_area_name"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
          <div class="chargearea" v-if="scope.row.free_area_name">
            <h3>免费区域：</h3>
            <div
              class="chargeareaDetail"
              v-for="(item, index) in scope.row.free_area_name"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间" width="180">
      </el-table-column>
      <el-table-column label="状态" width="160">
        <div class="Dflex" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.status == 1" type="success"
            >正常</el-tag
          >
          <el-tag size="mini" v-if="scope.row.status == 2" type="success"
            >禁用</el-tag
          >
        </div>
      </el-table-column>
      <el-table-column label="操作">
        <div class="caozuo" slot-scope="scope">
          <el-button
            size="mini"
            style="margin-right: 8px"
            type="success"
            @click="compile(scope.row.id)"
          >
            编辑
          </el-button>
          <el-button size="mini" @click="viewUsage(scope.row.id)" type="primary"
            >查看使用情况</el-button>
          <el-button size="mini" @click="deleteData(scope.row.id)" type="danger"
            >删除</el-button
          >
        </div>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px"
      @current-change="CrtChg"
      :current-page="form.page"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import { getExpressTemplate, delExpressTemplate } from "@/api/api.js";
export default {
  data() {
    return {
      form: {
        page: 1,
        name: "",
      },
      total: 1,
      tableData: [],
    };
  },
  methods: {
    CrtChg(e) {
      this.form.page = e;
      this.getExpressTemplate();
    },
    // 获取快递模板列表
    async getExpressTemplate() {
      const { data } = await getExpressTemplate(this.form);
      if (data.code != 200) return this.$message.error(data.data);
      this.tableData = data.data.data;
      console.log(this.tableData);
      this.total = data.data.total;
    },
    // 删除数据
    async deleteData(id){
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          let dataId = {
            id: id
          }
           const { data } = await delExpressTemplate(dataId); 
           if(data.code == 200){
             
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getExpressTemplate();
           }
           console.log('data===',data)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },

    addTemplate() {
      this.$router.push("/logistics/addLogisticsTemplate");
    },

    viewUsage(id) {
      this.$router.push({ path: "/logistics/viewUsage", query: { id } });
    },

    compile(id) {
      this.$router.push({
        path: `/logistics/addLogisticsTemplate?&id=${id}`,
      });
    },
    search() {
      this.page = 1;
      this.total = 0;
      this.getExpressTemplate();
    },
  },
  created() {
    this.getExpressTemplate();
  },
};
</script>
<style lang="less" scoped>
.box-card {
  flex: 1;
  .btnbox {
    .addGoods {
      background-color: #179bfe;
      color: #fff;
      padding: 6px 10px;
      border-radius: 3px;
    }
    .tiaojian {
      margin: 20px 0;
      padding: 10px;
      display: flex;
      align-items: center;
      position: relative;
      .left {
        strong {
          font-weight: normal;
          font-size: 14px;
          color: #606266;
          padding-top: 10px;
          margin-right: 2px;
        }
        .el-input {
          width: 300px;
          margin-right: 20px;
          /deep/.el-input__inner {
            height: 30px;
          }
        }
        .checkboxBox {
          padding-top: 10px;
          margin: 0 0 20px 0;
        }
      }
      .shaixuan {
        background-color: #18bdff;
        color: aliceblue;
        padding: 3px 10px;
        border-radius: 2px;
        position: absolute;
        bottom: 15px;
        left: 400px;
        &:hover {
          background-color: #1abe9c;
        }
      }
    }
  }

  .arealist {
    .chargearea {
      display: flex;
      align-items: baseline;
      padding: 10px 0;
      span {
        padding: 0 10px;
        display: block;
      }
    }
  }
}
</style>

</style>