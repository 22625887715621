import { render, staticRenderFns } from "./logisticsTemplate.vue?vue&type=template&id=4042b902&scoped=true&"
import script from "./logisticsTemplate.vue?vue&type=script&lang=js&"
export * from "./logisticsTemplate.vue?vue&type=script&lang=js&"
import style0 from "./logisticsTemplate.vue?vue&type=style&index=0&id=4042b902&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4042b902",
  null
  
)

export default component.exports